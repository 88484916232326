@tailwind base;
@tailwind components;
@tailwind utilities;

// general

body {
  font-family: 'Ubuntu', 'sans-serif';
  overflow-y: overlay;
}

.App {
  width: 100%;
  min-height: calc(100vh - 379px); //sottrarre menu e footer
}

// nav links

.active {
  color: #6e0028;

  &::before {
    content: '';
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #6e0028;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -10px;
  }
}

.custom-padding-table-event {
  th {
    &:nth-child(2) {
      padding-left: 3 !important;
    }
    &:nth-child(5) {
      padding-left: 0 !important;
    }
    &:nth-child(6) {
      padding-left: 0 !important;
    }
  }
}
.custom-padding-table-course {
  th {
    &:nth-child(2) {
      padding-left: 3 !important;
    }
    &:nth-child(4) {
      padding-left: 0 !important;
    }
    &:nth-child(5) {
      padding-left: 1 !important;
    }
    &:nth-child(6) {
      padding-left: 1 !important;
    }
  }
}

.PhoneInput {
  display: relative;
  margin-top: 0.25rem;

  .PhoneInputCountry {
    position: absolute;
    margin: 14px;
  }
  .PhoneInputCountrySelect {
    position: absolute;
  }
  .PhoneInputInput {
    border-radius: 5px;
    padding-left: 60px;
    border-color: rgb(209 213 219 / 1);
  }
  input:focus {
    border-color: #4c4c4c;
    box-shadow: none;
  }
  input:focus-visible {
    border-color: #4c4c4c;
  }
}
[type='text']:focus {
  box-shadow: none;
}
